import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import axiosIns from '../../axios';

import LoadingButton from "@mui/lab/LoadingButton";
import {
  TextField,
  FormControlLabel,
  Checkbox,
  Typography,
  Box,
  Container,
} from "@mui/material";

const styles = {
  container: {
    minHeight: "100vh",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "center",
  },
  box: {
    width: "100%",
    color: "rgb(71, 82, 89)",
    padding: "30px",
    backgroundColor: "#fff",
    borderRadius: "20px",
    boxShadow: "rgb(115 82 199 / 18%) 0px 0.5rem 1.25rem",
  },
  hero: {
    fontSize: "20px",
    fontWeight: 600,
    marginBottom: "20px",
  },
  desc: {
    fontSize: "14px",
    fontWeight: 300,
    lineHeight: "25px",
  },
};

const validate = (values) => {
  const errors = {};

  if (!values.email) {
    errors.email = "Email is required.";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }

  if (!values.password) {
    errors.password = "Password is required.";
  }

  return errors;
};

export default function Login() {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      navigate("/");
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      email: "pyt@hon.com",
      password: "123qwe",
    },
    validate,
    onSubmit: (values) => {
      setLoading(true);
      axiosIns.post('/auth/login', values).then(res => {
        toast.success('Login succeded, please wait..')
        localStorage.setItem('token', res.data.accessToken)

        setTimeout(() => {
          setLoading(false);
          window.location.href = "/"
        }, 1000)
      })
    },
  });

  return (
    <Container component="main" maxWidth="xs" sx={styles.container}>
      <Box sx={styles.box}>
        <form onSubmit={formik.handleSubmit}>
          <Typography sx={styles.hero}>Sign In</Typography>
          <TextField
            margin="normal"
            fullWidth
            label="Email Address"
            name="email"
            autoFocus
            size="small"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            helperText={formik.errors.email ? formik.errors.email : null}
          />

          <TextField
            margin="normal"
            fullWidth
            name="password"
            label="Password"
            type="password"
            size="small"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            helperText={formik.errors.password ? formik.errors.password : null}
          />

          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            loading={isLoading}
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </LoadingButton>
        </form>
      </Box>
    </Container>
  );
}
