import { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import toast from "react-hot-toast";
import moment from "moment";
import {
  Typography,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Avatar,
  ButtonGroup,
} from "@mui/material";

import { RiCheckFill, RiCloseFill } from "react-icons/ri";

import axiosIns from "../../axios";
import DashboardLayout from "../../layouts/dashboard";

const styles = {
  hero: {
    fontSize: "30px",
    fontWeight: 600,
  },
  contentBox: {
    marginTop: "20px",
    backgroundColor: "#fff",
    borderRadius: "20px",
    boxShadow: "rgb(115 82 199 / 18%) 0px 0.5rem 1.25rem",
  },
  boxHero: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "20px",
    borderBottom: "solid 1px #f1f1f1",
  },
  boxLabel: {
    fontSize: "16px",
    fontWeight: 500,
  },
  boxContent: {
    height: "calc(100vh - 200px)",
    paddingBottom: "15px",
  },
  tableContainer: {
    boxShadow: "unset!important",
  },
  fields: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
  },
  field: {
    flex: "0 0 49%",
    maxWidth: "49%",
  },
};

const SenderQueues = () => {
  const [tableData, setData] = useState([]);

  const getDatas = () => {
    axiosIns.get("/sender-queues").then((res) => {
      setData(res.data);
    });
  };

  useEffect(() => {
    getDatas();
  }, []);

  const columns = [
    {
      field: "imageUrl",
      headerName: "Image",
      width: 100,
      renderCell: (params) => (
        <Avatar alt={params.row.text} src={params.row.imageUrl} />
      ),
    },
    { field: "messagePlatform", headerName: "Platform", width: 100 },
    { field: "messageType", headerName: "Type", width: 150 },
    {
      field: "text",
      headerName: "Text",
      width: 750,
    },
    {
      field: "isDeleted",
      headerName: "Is Deleted",
      width: 130,
      renderCell: (params) =>
        params.row.isDeleted ? (
          <RiCheckFill size="1.5em" color="#3cd2a2" />
        ) : (
          <RiCloseFill size="1.5em" color="red" />
        ),
    },
    {
      field: "isActive",
      headerName: "Is Active",
      width: 130,
      renderCell: (params) =>
        params.row.isActive ? (
          <RiCheckFill size="1.5em" color="#3cd2a2" />
        ) : (
          <RiCloseFill size="1.5em" color="red" />
        ),
    },
    {
      field: "createdDate",
      headerName: "Create Date",
      width: 200,
      renderCell: (params) =>
        moment(params.row.createdDate).format("MM ddd YYYY - hh:mm"),
    },
  ];

  return (
    <DashboardLayout>
      <Typography sx={styles.hero}>Sender Queues</Typography>
      <Box sx={styles.contentBox}>
        <Box sx={styles.boxHero}>
          <Typography sx={styles.boxLabel}>Sender Queues</Typography>
        </Box>
        <Box sx={styles.boxContent}>
          <DataGrid
            rows={tableData}
            columns={columns}
            pageSize={13}
            rowsPerPageOptions={[13]}
          />
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default SenderQueues;
