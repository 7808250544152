import React from "react";
import ReactDOM from "react-dom/client";
import { Toaster } from 'react-hot-toast';
import { createBrowserRouter, RouterProvider, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import './assets/styles/reset.css'

import routes from './routes'
const router = createBrowserRouter(routes);

const theme = createTheme({
  typography: {
    fontFamily: [
      'Maven Pro',
      'Roboto',
    ].join(','),
    allVariants: {
      color: 'rgb(71, 82, 89)'
    }    
  },  
  palette: {
    primary: {
      main: "#7352c7",
    },    
  }
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Toaster />
      <RouterProvider router={router} />
    </ThemeProvider>
  </React.StrictMode>
);
