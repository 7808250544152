import { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import toast from "react-hot-toast";
import moment from "moment";
import {
  Typography,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Chip,
  Link,
  ButtonGroup,
} from "@mui/material";

import {
  RiCheckFill,
  RiCloseFill,
  RiDeleteBin2Line,
  RiEdit2Line,
} from "react-icons/ri";

import axiosIns from "../../axios";
import DashboardLayout from "../../layouts/dashboard";
import TelegramModal from "./modal";

const styles = {
  hero: {
    fontSize: "30px",
    fontWeight: 600,
  },
  contentBox: {
    marginTop: "20px",
    backgroundColor: "#fff",
    borderRadius: "20px",
    boxShadow: "rgb(115 82 199 / 18%) 0px 0.5rem 1.25rem",
  },
  boxHero: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "20px",
  },
  boxLabel: {
    fontSize: "16px",
    fontWeight: 500,
  },
  boxContent: {
    height: "calc(100vh - 200px)",
    paddingBottom: "15px",
  },
  tableContainer: {
    boxShadow: "unset!important",
  },
  fields: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
  },
  field: {
    flex: "0 0 49%",
    maxWidth: "49%",
  },
};

const TelegramList = () => {
  const [open, setOpen] = useState(false);
  const [tableData, setData] = useState([]);
  const [selectedRow, setRow] = useState(null);
  const [isRemoveAlertShown, setRemoteAlertVisibility] = useState(false);
  const [selectedForRemove, setForRemove] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setRow(null);
  };

  const getDatas = () => {
    axiosIns.get("/telegram-sessions").then((res) => {
      setData(res.data);
    });
  };

  useEffect(() => {
    getDatas();
  }, []);

  useEffect(() => {
    if (selectedRow) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [selectedRow]);

  const deleteRecord = () => {
    axiosIns
      .delete(`/telegram-sessions/${selectedForRemove.id}`)
      .then((res) => {
        toast.success("Session deleted..");

        setTimeout(() => {
          setRemoteAlertVisibility(false);
          getDatas();
        }, 1000);
      });
  };

  const columns = [
    { field: "name", headerName: "Name", width: 150 },
    { field: "dailyUsedAmount", headerName: "Daily Used Amount", width: 200 },
    { field: "totalUsedAmount", headerName: "Total Used Amount", width: 200 },
    {
      field: "eligibilityStatus",
      headerName: "Eligibility Status",
      width: 200,
      renderCell: (params) => (
        <Chip label={params.row.eligibilityStatus} color="primary" />
      ),
    },
    {
      field: "mediaUrl",
      headerName: "Media Url",
      width: 130,
      renderCell: (params) => <Link href={params.row.mediaUrl}>Media</Link>,
    },
    {
      field: "isBanned",
      headerName: "Is Banned",
      width: 130,
      renderCell: (params) =>
        params.row.isBanned ? (
          <RiCheckFill size="1.5em" color="#3cd2a2" />
        ) : (
          <RiCloseFill size="1.5em" color="red" />
        ),
    },
    {
      field: "isDeleted",
      headerName: "Is Deleted",
      width: 130,
      renderCell: (params) =>
        params.row.isDeleted ? (
          <RiCheckFill size="1.5em" color="#3cd2a2" />
        ) : (
          <RiCloseFill size="1.5em" color="red" />
        ),
    },
    {
      field: "isActive",
      headerName: "Is Active",
      width: 130,
      renderCell: (params) =>
        params.row.isActive ? (
          <RiCheckFill size="1.5em" color="#3cd2a2" />
        ) : (
          <RiCloseFill size="1.5em" color="red" />
        ),
    },
    {
      field: "createdDate",
      headerName: "Create Date",
      width: 200,
      renderCell: (params) =>
        moment(params.row.createdDate).format("MM ddd YYYY - hh:mm"),
    },
    {
      field: "action",
      headerName: "#",
      width: 130,
      renderCell: (params) => (
        <ButtonGroup variant="outlined">
          <Button
            size="small"
            variant="outlined"
            color="warning"
            onClick={() => setRow(params.row)}
          >
            <RiEdit2Line size="1.3em" />
          </Button>
          <Button
            size="small"
            variant="outlined"
            color="error"
            onClick={() => {
              setForRemove(params.row);
              setRemoteAlertVisibility(true);
            }}
          >
            <RiDeleteBin2Line size="1.3em" />
          </Button>
        </ButtonGroup>
      ),
    },
  ];

  return (
    <DashboardLayout>
      <TelegramModal
        open={open}
        handleClose={handleClose}
        reload={() => getDatas()}
        selected={selectedRow}
      />
      <Dialog
        open={isRemoveAlertShown}
        onClose={() => setRemoteAlertVisibility(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure continue?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You will be delete record are you sure?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setRemoteAlertVisibility(false)}>
            Cancel
          </Button>
          <Button onClick={() => deleteRecord()}>Yes, Delete</Button>
        </DialogActions>
      </Dialog>
      <Typography sx={styles.hero}>Telegram Sessions</Typography>
      <Box sx={styles.contentBox}>
        <Box sx={styles.boxHero}>
          <Typography sx={styles.boxLabel}>Telegram Sessions</Typography>
          <Button variant="contained" onClick={handleClickOpen}>
            Add Session
          </Button>
        </Box>
        <Box sx={styles.boxContent}>
          <DataGrid
            rows={tableData}
            columns={columns}
            pageSize={13}
            rowsPerPageOptions={[13]}
          />
        </Box>
      </Box>
    </DashboardLayout>
  );
};

export default TelegramList;
