// Containers
import Login from '../containers/Auth/login'
import Dashboard from '../containers/Dashboard'
import TelegramList from '../containers/Telegram/list'
import MessageContentsList from '../containers/MessageContents/list'
import SenderQueuesList from '../containers/SenderQueues/list'

export default [
  {
    path: "/",
    element: <Dashboard />,
  },  
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/telegram/list",
    element: <TelegramList />,
  },
  {
    path: "/message-contents/list",
    element: <MessageContentsList />,
  },   
  {
    path: "/sender-queues/list",
    element: <SenderQueuesList />,
  },      
];
