import Typography from "@mui/material/Typography";
import DashboardLayout from "../../layouts/dashboard";

const styles = {
  hero: {
    fontSize: "30px",
    fontWeight: 600,
  }
};

const Dashboard = () => {
  return (
    <DashboardLayout>
      <Typography sx={styles.hero}>Dashboard</Typography>
    </DashboardLayout>
  );
};

export default Dashboard;
