import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { RiTelegramLine, RiMessage3Line } from "react-icons/ri";
import { HiQueueList } from "react-icons/hi2";
import { useNavigate, useLocation } from "react-router-dom";

const pages = [
  {
    title: "Telegram Sessions",
    link: "/telegram/list",
    icon: <RiTelegramLine size="1.5em" />,
  },
  {
    title: "Message Contents",
    link: "/message-contents/list",
    icon: <RiMessage3Line size="1.5em" />,
  },
  {
    title: "Sender Queues",
    link: "/sender-queues/list",
    icon: <HiQueueList size="1.5em" />,
  },
];

const styles = {
  wrapper: {
    minHeight: "100vh",
  },
  sidebar: {
    position: 'fixed',
    left: 0,
    top: 0,
    width: '270px',
    padding: "30px 0",
    height: '100vh',
    backgroundColor: "#fff",
    boxShadow: "rgb(0 0 0 / 9%) 0px 5px 10px",
    zIndex: 5
  },
  content: {
    padding: "20px",
    paddingLeft: '300px',
    position: 'relative'
  },
  menu: {
    marginTop: "30px",
    paddingRight: "30px",
  },
  label: {
    opacity: 0.4,
    fontSize: "10px",
    marginLeft: "25px",
    fontWeight: "600",
    marginBottom: "10px",
  },
  link: {
    "&.MuiButtonBase-root": {
      display: "flex",
      alignItems: "center",
      width: "100%",
      paddingLeft: "25px",
      color: "#485259",
      marginTop: "10px",
      textTransform: "capitalize",
      justifyContent: "flex-start",
      borderRadius: "0 10px 10px 0",

      "& svg": {
        marginRight: " 5px",
      },
    },
  },
  active: {
    '&.MuiButtonBase-root': {
      color: '#fff',
      backgroundColor: '#7352c7'      
    }
  }
};

const DashboardLayout = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (!token) {
      navigate("/login");
    }
  }, []);

  const logout = () => {
    localStorage.removeItem('token')

    setTimeout(() => {
      navigate('/login')
    }, 500)
  }

  const isActive = path => {
    if(location.pathname === path) {
      return styles.active
    }
    return null
  };

  return (
    <>
      <Box sx={styles.wrapper}>
        <Box sx={styles.sidebar}>
          <Box sx={styles.menu}>
            <Box>
              <Typography sx={styles.label}>MENU</Typography>
              {pages.map((page) => (
                <Button variant="text" sx={[styles.link, isActive(page.link)]} onClick={() => navigate(page.link)}>
                  {page.icon}
                  {page.title}
                </Button>
              ))}
            </Box>

            <Box sx={{ position: 'absolute', left: 20, bottom: 20, zIndex: 5 }}>
              <Button variant="outlined" onClick={() => logout()}>Logout</Button>
            </Box>
          </Box>
        </Box>
        <Box sx={styles.content}>{props.children}</Box>
      </Box>
    </>
  );
};

export default DashboardLayout;
