import axios from "axios";
import toast from "react-hot-toast";

const axiosIns = axios.create({
  baseURL:
    "https://messageapi.tokenhouse.systems/",
  timeout: 20000,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
});

axiosIns.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.data) {
      if (error.response.data.error) {
        toast.error(error.response.data.error);
      } else {
        toast.error("An error occurred");
      }
    } else {
      toast.error("An error occurred");
    }
    throw error;
  }
);

export default axiosIns;
