import { useState, useEffect } from "react";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import {
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";

import axiosIns from "../../axios";

const styles = {
  hero: {
    fontSize: "30px",
    fontWeight: 600,
  },
  contentBox: {
    marginTop: "20px",
    backgroundColor: "#fff",
    borderRadius: "20px",
    boxShadow: "rgb(115 82 199 / 18%) 0px 0.5rem 1.25rem",
  },
  boxHero: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "20px",
    borderBottom: "solid 1px #f1f1f1",
  },
  boxLabel: {
    fontSize: "16px",
    fontWeight: 500,
  },
  boxContent: {
    paddingBottom: "15px",
  },
  tableContainer: {
    boxShadow: "unset!important",
  },
  fields: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
  },
  field: {
    flex: "0 0 49%",
    maxWidth: "49%",
  },
};

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = "Name is required.";
  }

  if (!values.totalUsedAmount) {
    errors.totalUsedAmount = "Amount is required.";
  }

  if (!values.mediaUrl) {
    errors.mediaUrl = "Media Url is required.";
  }

  return errors;
};

const TelegramModal = ({ handleClose, open, reload, selected }) => {
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: selected ? selected.name : "",
      mediaUrl: selected ? selected.mediaUrl : "",
      totalUsedAmount: selected ? selected.totalUsedAmount : 0,
      isBanned: selected ? selected.isBanned : false,
    },
    validate,
    onSubmit: (values) => {
      if(selected) {
        axiosIns.put(`/telegram-sessions/${selected.id}`, values).then(res => {
          toast.success('Session updated..')
  
          handleClose()
          setTimeout(() => {
            reload();
          }, 1000)
        })      
      } else {
        axiosIns.post('/telegram-sessions', values).then(res => {
          toast.success('Session added..')
  
          handleClose()
          setTimeout(() => {
            reload();
          }, 1000)
        })      
      }
    },
  });

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <form onSubmit={formik.handleSubmit}>
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {selected ? "Edit Session" : "Add Session"}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Box sx={styles.fields}>
            <Box sx={styles.field}>
              <TextField
                margin="normal"
                fullWidth
                label="Name"
                name="name"
                autoFocus
                size="small"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                helperText={formik.errors.name ? formik.errors.name : null}
              />
            </Box>
            <Box sx={styles.field}>
              <TextField
                margin="normal"
                fullWidth
                label="Total Used Amount"
                name="totalUsedAmount"
                autoFocus
                size="small"
                type="number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.totalUsedAmount}
                helperText={
                  formik.errors.totalUsedAmount
                    ? formik.errors.totalUsedAmount
                    : null
                }
              />
            </Box>
            <Box sx={{...styles.field, flex: '0 0 100%', maxWidth: '100%'}}>
              <TextField
                margin="normal"
                fullWidth
                label="Media Url"
                name="mediaUrl"
                autoFocus
                size="small"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.mediaUrl}
                helperText={
                  formik.errors.mediaUrl ? formik.errors.mediaUrl : null
                }
              />
            </Box>            
            <Box sx={styles.field}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="isBanned"
                    color="primary"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.isBanned}
                  />
                }
                label="Is Banned"
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button autoFocus type="submit">
            {selected ? 'Update' : 'Add'}
          </Button>
        </DialogActions>
      </form>
    </BootstrapDialog>
  );
};

export default TelegramModal;
